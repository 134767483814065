import React from 'react'

import { GiGraduateCap, GiInspiration, GiPodiumWinner } from "react-icons/gi";
import { GrWorkshop } from "react-icons/gr";
import { FcBusinessman } from "react-icons/fc";
import { FaBlog, FaMoneyBillWave } from 'react-icons/fa';
import { IoSchoolSharp } from "react-icons/io5";
import { MdOutlinePlayLesson, MdSportsScore } from "react-icons/md";
import { BiAnalyse, BiCategoryAlt } from 'react-icons/bi';
import { HiOutlineUsers } from "react-icons/hi";
import { MdCategory, MdContacts, MdEvent, MdLesson } from 'react-icons/md';
import { AiFillDatabase } from 'react-icons/ai';
import { BsQuestionSquare } from 'react-icons/bs';


const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const Blog = React.lazy(() => import("../pages/blog/Blog"));
const Course = React.lazy(() => import("../pages/course/Course"));

const Scholarship = React.lazy(() => import("../pages/scholarship/Scholarship"));
const Lesson = React.lazy(() => import("../pages/lesson/Lesson"));
const Event = React.lazy(() => import("../pages/event/Event"));
const Resource = React.lazy(() => import("../pages/resource/Resource"));
const BlogCategory = React.lazy(() => import("../pages/blogCategory/BlogCategory"));
const Subscriber = React.lazy(() => import("../pages/subscriber/Subscriber"));
const Funding = React.lazy(() => import("../pages/funding/Funding"));
const Contact = React.lazy(() => import("../pages/contact/Contact"));
const Question = React.lazy(() => import("../pages/questions/Question"));
const Scoreboard = React.lazy(() => import("../pages/scoreboard/Scoreboard"));
const Winner = React.lazy(() => import("../pages/winner/Winner"));

export const adminRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <BiAnalyse size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/busCategory",
        element: <BlogCategory />,
        name: "Blog Category",
        icon: <MdCategory size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/blog",
        element: <Blog />,
        name: "Blog",
        icon: <FaBlog size={"1.5em"} />,
        sideBarVisible: true,
    },
    // {
    //     path: "/dashboard/level",
    //     element: <Level />,
    //     name: "Level",
    //     icon: <MdOutlineBusinessCenter size={"1.5em"} />,
    //     sideBarVisible: true,
    // },
    {
        path: "/dashboard/course",
        element: <Course />,
        name: "Course",
        icon: <IoSchoolSharp size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/lesson",
        element: <Lesson />,
        name: "Lesson",
        icon: <MdOutlinePlayLesson size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/event",
        element: <Event />,
        name: "Event",
        icon: <MdEvent size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/resource",
        element: <Resource />,
        name: "Resource",
        icon: <AiFillDatabase size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/scholarship",
        element: <Scholarship />,
        name: "Scholarship",
        icon: <GiGraduateCap size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/funding",
        element: <Funding />,
        name: "Funding",
        icon: <FaMoneyBillWave size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/contact",
        element: <Contact />,
        name: "Contact",
        icon: <MdContacts size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/questions/:page",
        element: <Question />,
        name: "Questions",
        icon: <BsQuestionSquare size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/scoreboard",
        element: <Scoreboard />,
        name: "Scoreboard",
        icon: <MdSportsScore size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/winner",
        element: <Winner />,
        name: "Winners",
        icon: <GiPodiumWinner size={"1.5em"} />,
        sideBarVisible: true
    }
    // {
    //     path: "/subscribers",
    //     element: <Subscriber />,
    //     name: "Subscribers",
    //     icon: <HiOutlineUsers size={"1.5em"} />,
    //     sideBarVisible: true,
    // }
];
